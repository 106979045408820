import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import Img from "gatsby-image"
import { Display } from "../components/typography"
import RichContentTextBlock from "../components/richContentTextBlock"
import PageTitle from "../components/pageTitle"
import Gut from "../components/gut"
import { Helmet } from "react-helmet"
import { HelmetDatoCms } from "gatsby-source-datocms"

const Page = ({ data: { page } }) => {
  return (
    <Layout>
      <SEO title={page.title} />
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <PageTitle>
                <Display align="center">{page.title}</Display>
              </PageTitle>
            </Col>
          </Row>
          <Gut gutter={4}>
            {page.image && (
              <>
                <Helmet>
                  <meta property="og:image" content={page.image.url} />
                </Helmet>
                <Row>
                  <Col>
                    <Img fluid={page.image.fluid} />
                  </Col>
                </Row>
              </>
            )}
            <CustomRow justify="center">
              {page.summary && (
                <Col lg={4}>
                  <RichContentTextBlock html={page.summary} />
                </Col>
              )}
              <Col lg={8}>
                <RichContentTextBlock html={page.text} />
              </Col>
            </CustomRow>
          </Gut>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomRow = styled(Row)`
  @media screen and (max-width: 991px) {
    & > * {
      margin-bottom: 3rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export default Page

export const query = graphql`
  query PageQuery($originalId: String!) {
    page: datoCmsPage(originalId: { eq: $originalId }) {
      title
      slug
      text
      summary
      image {
        fluid(maxWidth: 980) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
